/** @format */

import React from "react";
import Stake from "../components/elements/Stake";
import Leaderboard from "../components/elements/Leaderboard";

const Home = () => {
  React.useEffect(() => {
    document.title = "Yield 24 - Dashboard";
  }, []);
  return (
    <React.Fragment>
      <Leaderboard />
    </React.Fragment>
  );
};

export default Home;
