/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  getAuth,
  onAuthStateChanged,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import CountUp from "react-countup";
import { firebase } from "../utils/Firebase";
import useRequest from "@ahooksjs/use-request";

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const lowercasedFilter = searchQuery.toLowerCase();

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(process.env.REACT_APP_Y24_API);
      setUsers(response?.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  function truncate(str, n) {
    return str.length > n ? str.slice(0, 2) + "..." + str.slice(-1) : str;
  }
  const handleSearch = event => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users.filter(item =>
    Object.keys(item).some(
      key =>
        typeof item[key] === "string" &&
        item[key].toLowerCase().includes(lowercasedFilter),
    ),
  );
  React.useEffect(() => {
    document.title = "Yield 24 - Leaderboard";
  }, []);

  // const fetchState = fetchData();

  useRequest(fetchData, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });

  const { run, cancel } = useRequest(fetchData, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });
  useEffect(() => {
    run();
    return () => {
      cancel();
    };
  }, [run, cancel]);

  const auth = getAuth(firebase);
  const [score, setScore] = useState(null);

  const twitterLogin = async () => {
    try {
      const provider = new TwitterAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const credentials = TwitterAuthProvider.credentialFromResult(result);
      const token = credentials.accessToken;
      const secret = credentials.secret;
      const userLogin = result.user;
      const twitter_id = userLogin?.uid;
      axios.post("https://yield24api.onrender.com/api/getScore", {
        twitter_id,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async authUser => {
      if (authUser) {
        const twitter_id = authUser?.uid;
        if (twitter_id) {
          try {
            const getScoreResponse = await axios.post(
              "https://yield24api.onrender.com/api/getScore",
              {
                twitter_id,
              },
            );
            // console.log(getScoreResponse?.data);
            setScore(getScoreResponse?.data?.data);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        }
      } else {
        // User is signed out.
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <React.Fragment>
      <div className='flex-grow w-full md:max-w-[65%] lg:max-w-[75%] ms-auto    pt-3 top-0 md:sticky backdrop-blur-xl '>
        <div className='container m-auto px-6 text-gray-500 md:px-10  '>
          <img
            src='assets/new.gif'
            className='w-20 absolute top-0 -left-20'
            alt=''
          />
          <marquee  className='bg-slate-300 position-absolute  w-full md:max-w-[65%] lg:max-w-[95%] shadow-xl rounded-b-lg items-center px-2  py-2 font-para font-semibold text-sm text-black end-0 left-0 top-0 logout bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]'>
            {filteredUsers
              .filter(person => person.spin)
              .map((person, id) => (
                <span
                  className='text-sm font-semibold mb-0 text-gray-900 mx-4'
                  key={id}>
                  {person?.userName}
                </span>
              ))}
            {/* <div className=' relative flex overflow-x-hidden'>
              <div className='animate-marquee whitespace-nowrap transition-all'>
                {filteredUsers
                  .filter(person => person.spin)
                  .map((person, id) => (
                    <span
                      className='text-sm font-semibold mb-0 text-gray-900 mx-4'
                      key={id}>
                      {person?.userName}
                    </span>
                  ))}
              </div>
              <div className='absolute top-0  animate-marquee2 whitespace-nowrap transition-all'>
                {filteredUsers
                  .filter(person => person.deposit)
                  .map((person, id) => (
                    <span
                      className='text-sm font-semibold mb-0 text-gray-900 mx-4'
                      key={id}>
                      {person?.userName}
                    </span>
                  ))}
              </div>
            </div> */}
          </marquee>

          <div className='flex justify-between flex-wrap items-center mt-5'>
            <div>
              <h3 className='text-xl md:text-3xl   font-semibold bg-gradient-to-br  from-[#27252e] to-[#9d9d9e] text-transparent bg-clip-text font-head '>
                Leaderboard
              </h3>
              <p className=' font-semibold'>
                Bridge & Invite friends to rank up
              </p>
            </div>
            <div className='pr-0 md:pr-14'>
              {score?.id ? (
                <div className='flex min-w-0 gap-x-4 bg-[#c1c1c7] px-4 py-2 rounded-lg shadow-md'>
                  <img
                    className='h-12 w-12 flex-none rounded-full bg-gray-50'
                    src={score?.imgUrl}
                    alt=''
                  />
                  <div className='min-w-0 flex-auto'>
                    <p className='text-sm font-bold leading-6 text-gray-900'>
                      {score?.userName}
                    </p>
                    <p className='mt-1 truncate text-xs leading-5 text-gray-600'>
                      <strong>
                        {" "}
                        <CountUp delay={2} start={0} end={score?.spinAmount} />
                      </strong>{" "}
                      Y24
                    </p>
                  </div>
                </div>
              ) : (
                <button
                  onClick={twitterLogin}
                  disabled={loading}
                  className='flex mt-10 w-44 items-center  font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                  {loading ? (
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    <React.Fragment>
                      Login with &nbsp;{" "}
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-twitter-x'
                        viewBox='0 0 16 16'>
                        <path d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z' />
                      </svg>
                    </React.Fragment>
                  )}
                </button>
              )}
            </div>
          </div>
          <div className='pr-0 md:pr-20 mt-3'>
            <React.Fragment>
              <div className=' w-full max-w-sm ms-auto flex items-center'>
                <form className='formSer ms-auto'>
                  <button>
                    <svg
                      width={17}
                      height={16}
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      role='img'
                      aria-labelledby='search'>
                      <path
                        d='M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9'
                        stroke='currentColor'
                        strokeWidth='1.333'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </button>
                  <input
                    className='input'
                    placeholder='Search...'
                    required=''
                    type='text'
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                  <button className='reset' type='reset'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      strokeWidth={2}>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M6 18L18 6M6 6l12 12'
                      />
                    </svg>
                  </button>
                </form>
                <button
                  className='hover:rotate-180 flex ml-4'
                  onClick={fetchData}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    fill='currentColor'
                    class='bi bi-arrow-clockwise'
                    viewBox='0 0 16 16'>
                    <path
                      fill-rule='evenodd'
                      d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z'
                    />
                    <path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466' />
                  </svg>
                </button>
              </div>
              <div className='row border-b border-t border-gray-600 ml-0 pl-0 mt-4 py-3'>
                <div className='col-2'>
                  <div className='font-head'>
                    <h5 className='text-sm font-bold  mb-0 text-gray-900'>
                      RANK
                    </h5>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='font-head'>
                    <h5 className='text-sm font-bold mb-0 text-gray-900'>
                      Name
                    </h5>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='font-head'>
                    <h5 className='text-sm font-bold mb-0 text-gray-900'>
                      INVITED BY
                    </h5>
                  </div>
                </div>
                <div className='col-3'>
                  <div className='font-head text-end'>
                    <h5 className='text-sm mb-0 text-gray-900 '>Y24 TOKEN</h5>
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </div>
      </div>
      <div className=' w-full md:max-w-[65%] lg:max-w-[75%] ms-auto   pt-3'>
        <div className='container m-auto px-6 text-gray-500 md:px-10  '>
          <div className='pr-0 md:pr-14'>
            {filteredUsers.length === 0 ? (
              <div className='w-full h-72 flex justify-center items-center'>
                <div className='spinner'>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            ) : (
              <div className='row divide-y divide-gray-400 ml-0 pl-0  '>
                {filteredUsers
                  .filter(person => person.spin)
                  .sort((a, b) => b.spinAmount - a.spinAmount)
                  .map((person, id) => (
                    <div className='row  py-4' key={person.id}>
                      <div className='col-2'>
                        <div className='font-head '>
                          <div className='bg-[#9d9d9e]  py-1 w-16 flex items-center justify-center'>
                            <h5 className='text-sm font-semibold mb-0 text-gray-900 '>
                              {id + 1}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <div className=' flex items-center justify-start'>
                          <div>
                            <img
                              className='h-8 w-8   rounded-full '
                              src={person.imgUrl}
                              alt=''
                            />
                          </div>
                          <h5 className='text-sm ml-2 mb-0 font-semibold font-para text-gray-900 font'>
                            {person.userName}
                          </h5>
                        </div>
                      </div>
                      <div className='col-3'>
                        <div className='font-para'>
                          <h5 className='text-sm font-semibold mb-0 text-gray-900'>
                            {person.refName}
                          </h5>
                        </div>
                      </div>
                      <div className='col-3 text-end'>
                        <div className='font-head'>
                          <h5 className='text-sm  text-gray-900 mb-0'>
                            <CountUp
                              delay={2}
                              start={0}
                              end={person?.spinAmount}
                            />{" "}
                            Y24
                          </h5>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Leaderboard;
