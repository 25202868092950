/** @format */

import { IoCopyOutline } from "react-icons/io5";
import React, { useState, useEffect } from "react";
import Faqs from "../utils/Faqs";
import { toast } from "react-toastify";
import numbro from "numbro";
import { utils } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";
import Web3 from "web3";
import { ErcABI, ErcContractAddress } from "../Contracts/Abi";
import useBalance from "../Contracts/useBalance";
import CountUp from "react-countup";

const Stake = () => {
  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text successfully copied to clipboard:", text);
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard:", err);
      });
  }

  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { open, close } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");
  function truncate(str, n) {
    return str.length > n ? str.slice(0, 4) + "..." + str.slice(-4) : str;
  }
  const [bnbAmount, setBnbAmount] = useState(null);
  const [reward, setReward] = useState(null);
  const [balance, setBalance] = useState(null);
  const [btn, setBtn] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingUn, setloadingUn] = useState(false);

  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };
  const [selected, setSelected] = useState(1);
  const handleButton = buttonId => {
    setSelected(buttonId);
    setBnbAmount("");
  };
  const bal = () => {
    if (address) {
      web3.eth.getBalance(address).then(balance => {
        setBalance(getFullDisplayBalance(balance));
      });
    }
  };
  const [yBNBbalance] = useBalance(
    "0x99B4D0b0a078AD86B3867C3822895E9705f7d56c",
    18,
  );

  bal();

  function calcPerc(amount) {
    setBnbAmount((balance / 100) * amount);
  }
  function calcPerc1(amount) {
    setBnbAmount((yBNBbalance / 100) * amount);
  }
  // console.log(bnbAmount);
  function purchaseSuccessful() {
    setBtn(false);
    toast.success("You have successfully deposit!");
    setBnbAmount("");
  }

  function purchaseFailed() {
    setBtn(false);
    toast.error("Unable to handle your request, try again!");
  }
  const handleDeposit = async () => {
    try {
      console.log(bnbAmount, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }

      if (!bnbAmount || isNaN(bnbAmount) || bnbAmount <= 0) {
        setBtn(false);
        return toast.error("Amount should be a valid number greater than 0");
      }

      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();
      const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
      const invest = await contract.methods.deposit().send({
        from: address,
        value: web3.utils.toWei(String(bnbAmount), "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      });

      if (invest) {
        purchaseSuccessful();
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };

  const handleUnStake = async () => {
    if (address) {
      if (bnbAmount != "" && bnbAmount != null && bnbAmount != 0) {
        try {
          setloadingUn(true);
          const rpcGasPrice = await web3.eth.getGasPrice();
          const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
          const withdraw = contract.methods
            .withdraw(web3.utils.toWei(String(bnbAmount), "ether"))
            .send({
              from: address,
              // gas: 1000000,
              gasPrice: rpcGasPrice,
            })
            .then(res => {
              setloadingUn(false);
              toast.success("Successfully UnStake");
              setBnbAmount("");
            })
            .catch(er => {
              setloadingUn(false);
              setBnbAmount("");
              toast.error(
                "Something went wrong, you can ask in telegram group about this issue",
              );
            });
        } catch (error) {
          setBtn(false);
          // console.log(5, error);
        }
      } else {
        setloadingUn(false);
        toast.error("Amount should not be empty");
      }
    } else {
      setloadingUn(false);
      toast.info(
        "Unable to detect any wallet, please connect metamask or trust wallet",
      );
    }
  };

  const handleHarvest = async () => {
    setloading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();
    const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
    const harvest = contract.methods
      .harvest()
      .send({
        from: address,
        value: web3.utils.toWei("0", "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      })
      .then(res => {
        setloading(false);
        toast.success("Successfully Harvest");
      })
      .catch(er => {
        setloading(false);
        toast.error(
          "Something went wrong, you can ask in telegram group about this issue",
        );
      });
  };

  const handlePendingReward = async () => {
    const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
    const pendingReward = contract.methods
      .pendingReward(address)
      .call()
      .then(res => {
        setReward(getFullDisplayBalance(res));
        // console.log(res);
      })
      .catch(er => {});
  };

  useEffect(() => {
    if (chain?.id === 56) {
      handlePendingReward();
      const interval = setInterval(() => {
        handlePendingReward();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [address]);


  React.useEffect(() => {
    document.title = "Yield 24 - LRT Staking";
  }, []);


  // console.log("earn ====>", earn);
  return (
    <div className='flex-grow w-full md:max-w-[65%] lg:max-w-[80%] ms-auto   relative pt-5'>
      <div className='container m-auto px-6 text-gray-500 md:px-10 xl:px-0 '>
        <div className='card_main rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8 max-w-3xl m-auto relative overflow-hidden'>
          <div className=' space-y-4'>
            <div className='position-absolute flex w-full justify-center items-center px-3 py-2 font-para font-semibold text-sm text-black end-0 top-0 logout bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]'>
              Token Address:{" "}
              <small className='ml-2 text-xs'>
                {" "}
                0x99B4D0b0a078AD86B3867C3822895E9705f7d56c
              </small>{" "}
              <span
                onClick={() =>
                  copyToClipboard("0x99B4D0b0a078AD86B3867C3822895E9705f7d56c")
                }>
                <IoCopyOutline
                  title='Copy Token Address'
                  className='ml-3 cursor-pointer hover:opacity-60  transform hover:scale-105 hover:shadow-lg   overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                />
              </span>
            </div>
            <h3 className='text-xl md:text-3xl flex justify-center  font-semibold bg-gradient-to-br  from-[#27252e] to-[#9d9d9e] text-transparent bg-clip-text font-head text-center'>
              Y24 lets you stake BNB to get daily rewards.
              {/* <span className='flex mt-10 w-32 text-center font-para text-xl  font-semibold items-center  font-para py-2 px-2  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                BNB
              </span> */}
            </h3>
            <p className='text-center'>
              Stake any amount and grow your balance with DeFi 24h redemption.
            </p>
          </div>
          {isConnected && (
            <div
              class='Referred m-auto my-2 font-para font-semibold text-sm'
              onClick={open}>
              {truncate(`${ensName ? `${ensName} (${address})` : address}`, 8)}
            </div>
          )}
          <div className='  flex  my-5   justify-around font-head'>
            <h3 className=' block  font-medium text-black text-2xl'>
              Restake{" "}
            </h3>
            <h3 className='  font-bold text-2xl flex flex-col justify-end items-end'>
              {/* {reward ? parseFloat(reward).toFixed(2) + " BNB" : "0 BNB"}{" "} */}
              <span>
                {reward ? (
                  <span>
                    <CountUp
                      delay={2}
                      start={0}
                      end={parseFloat(reward).toFixed(2)}
                    />{" "}
                    yBNB
                  </span>
                ) : (
                  "0 yBNB"
                )}
              </span>
              <button
                onClick={handleHarvest}
                disabled={loading || yBNBbalance > 0 ? false : true}
                className='flex max-w-4xl btn font-para text-lg  border-0 fw-bold items-center  font-para py-2 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                {loading ? (
                  <>
                    <div
                      class='spinner-border text-dark spinner-border-sm'
                      role='status'>
                      <span class='sr-only'>Loading...</span>
                    </div>
                    &nbsp; Processing transaction...
                  </>
                ) : (
                  "Harvest"
                )}
              </button>
            </h3>
          </div>

          {isConnected ? (
            <React.Fragment>
              {chain?.id === 56 ? (
                <div>
                  <div className='w-3/4 flex items-center m-auto '>
                    <div
                      className={`btn_buy ${
                        selected === 1
                          ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                          : "not-selected bg-[#c1c1c7]"
                      }`}
                      onClick={() => handleButton(1)}>
                      Deposit
                    </div>
                    <div
                      className={`btn_sell ${
                        selected === 2
                          ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                          : "not-selected bg-[#c1c1c7]"
                      }`}
                      onClick={() => handleButton(2)}>
                      UnStake
                    </div>
                  </div>
                  {selected === 1 ? (
                    <React.Fragment>
                      <div className='my-5 '>
                        <div className='relative flex w-full max-w-[24rem] m-auto '>
                          <div className='relative h-12 w-full min-w-[200px] '>
                            <input
                              type='number'
                              className='peer h-full w-full rounded-[7px] font-head  borde  border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                              placeholder=' '
                              value={bnbAmount || ""}
                              onChange={e => setBnbAmount(e.target.value)}
                            />
                            <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px]  leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                              Enter Amount
                            </label>
                          </div>
                          <button
                            onClick={() => calcPerc(100)}
                            className='!absolute bt right-1 top-2 select-none disabled:cursor-not-allowed rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type='button'>
                            Max
                          </button>
                        </div>
                        <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                          Balance:{" "}
                          <strong>
                            {" "}
                            {balance
                              ? parseFloat(balance).toFixed(3) + " BNB"
                              : "0"}
                          </strong>
                        </h5>
                      </div>
                      <div className='text-center'>
                        <button
                          onClick={() => {
                            handleDeposit();
                          }}
                          disabled={btn}
                          className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                          <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                            {btn ? (
                              <>
                                <div
                                  class='spinner-border text-dark spinner-border-sm'
                                  role='status'>
                                  <span class='sr-only'>Loading...</span>
                                </div>
                                &nbsp; Processing transaction...
                              </>
                            ) : (
                              <span className='mx-4'>Stake</span>
                            )}
                          </span>
                        </button>
                      </div>

                      <div className='  flex  mt-5  items-center justify-between sm:w-2/4 w-full m-auto  font-head'>
                        <p className=' block  font-medium text-black text-base'>
                          You will receive
                        </p>
                        <p className='  font-bold text-base flex flex-col justify-end items-end'>
                          {bnbAmount ? bnbAmount : "0.00"} yBNB
                        </p>
                      </div>
                      <div className='  flex  mt-3  items-center justify-between sm:w-2/4 w-full m-auto font-head'>
                        <p className=' block  font-medium text-black text-base'>
                          Exchange rate: 1 BNB
                        </p>
                        <p className='  font-bold text-base flex flex-col justify-end items-end'>
                          1 yBNB
                        </p>
                      </div>
                      <div className='  flex  mt-3  items-center justify-between sm:w-2/4 w-full m-auto font-head'>
                        <p className=' block  font-medium text-black text-base'>
                          Max transaction cost
                        </p>
                        <p className='  font-bold text-base flex flex-col justify-end items-end'>
                          $0.36
                        </p>
                      </div>
                      <div className='  flex  mt-3  items-center justify-between sm:w-2/4 w-full m-auto font-head'>
                        <p className=' block  font-medium text-black text-base'>
                          Annual percentage rate
                        </p>
                        <p className='  font-bold text-base flex flex-col justify-end items-end'>
                          10%
                        </p>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className='my-5 '>
                        <div className='relative flex w-full max-w-[24rem] m-auto'>
                          <div className='relative h-12 w-full min-w-[200px]'>
                            <input
                              type='number'
                              className='peer h-full w-full rounded-[7px] font-head  borde  border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                              placeholder=' '
                              value={bnbAmount || ""}
                              onChange={e => setBnbAmount(e.target.value)}
                            />
                            <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                              Enter Amount
                            </label>
                          </div>
                          <button
                            onClick={() => calcPerc1(100)}
                            className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                            type='button'>
                            Max
                          </button>
                        </div>
                        <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                          Balance:{" "}
                          <strong>
                            {" "}
                            {yBNBbalance
                              ? parseFloat(yBNBbalance).toFixed(3) + " yBNB"
                              : "0"}
                          </strong>
                        </h5>
                      </div>
                      <div className='text-center'>
                        <button
                          onClick={() => {
                            handleUnStake();
                          }}
                          disabled={loadingUn}
                          className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                          <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                            {loadingUn ? (
                              <>
                                <div
                                  class='spinner-border text-dark spinner-border-sm'
                                  role='status'>
                                  <span class='sr-only'>Loading...</span>
                                </div>
                                &nbsp; Processing transaction...
                              </>
                            ) : (
                              <span className='mx-4'>UnStake</span>
                            )}
                          </span>
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <div className='text-center'>
                  {chains.map(x => (
                    <button
                      disabled={!switchNetwork || x.id === chain?.id}
                      key={x.id}
                      className='flex max-w-4xl btn font-para text-lg m-auto  border-0 fw-bold items-center  font-para py-3 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.(x.id)}>
                      Switch {x.name} &nbsp;
                      {isLoading && pendingChainId === x.id && (
                        <div
                          class='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              )}
            </React.Fragment>
          ) : (
            <div
              onClick={open}
              className='flex  w-52 items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
              <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                Connect Wallet
              </span>
            </div>
          )}
        </div>
        <div className='flex justify-center mt-4'></div>
      </div>
      <Faqs />
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        // xmlns:svgjs='http://svgjs.dev/svgjs'
        className=' absolute bottom-0 w-full -z-50'
        width={1440}
        height={250}
        preserveAspectRatio='none'
        viewBox='0 0 1440 250'>
        <g mask='url("#SvgjsMask1006")' fill='none'>
          <rect
            width={1440}
            height={250}
            x={0}
            y={0}
            fill='rgba(255, 255, 255, 0)'
          />
          <path
            d='M26 250L276 0L364.5 0L114.5 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M278.6 250L528.6 0L782.1 0L532.1 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M504.20000000000005 250L754.2 0L884.7 0L634.7 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M715.8000000000001 250L965.8000000000001 0L1261.3000000000002 0L1011.3000000000001 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M1401 250L1151 0L1092.5 0L1342.5 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1178.4 250L928.4000000000001 0L700.4000000000001 0L950.4000000000001 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M936.8 250L686.8 0L418.29999999999995 0L668.3 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M710.1999999999999 250L460.19999999999993 0L143.69999999999993 0L393.69999999999993 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1304.192611426263 250L1440 114.19261142626311L1440 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M0 250L135.8073885737369 250L 0 114.19261142626311z'
            fill='url("#SvgjsLinearGradient1008")'
          />
        </g>
        <defs>
          <mask id='SvgjsMask1006'>
            <rect width={1440} height={250} fill='#ffffff' />
          </mask>
          <linearGradient
            x1='0%'
            y1='100%'
            x2='100%'
            y2='0%'
            id='SvgjsLinearGradient1007'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
          <linearGradient
            x1='100%'
            y1='100%'
            x2='0%'
            y2='0%'
            id='SvgjsLinearGradient1008'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Stake;
