/** @format */

import React from "react";

const Header = () => {
  return (
    <div>
      <header className=' sticky top-0  '>
        {/* Navigaiton Bar */}
        <nav className='flex items-center py-8 px-14 justify-between relative'>
          <div className='flex items-center'>
            <img src='assets/logo-hr.png' className='w-56 block' alt='' />
            {/* <h1 className='text-xl md:text-4xl font-head font-black text-gray-700 ml-3 bg-gradient-to-br  from-[#57546C] to-[#b4b4b4] text-transparent bg-clip-text'>
              Yield <span>24</span>
            </h1> */}
          </div>
          <div className='flex items-center'>
            <div className='mr-10'>
              <ul className='md:flex space-x-8 hidden '>
                <li>
                  <a href='#!' className='text-gray-800 font-bold'>
                    Documentation
                  </a>
                </li>
              </ul>
            </div>

            <div className='md:flex mt-10 w-52 hidden items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
              <button>Connect Wallet</button>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={16}
                  height={16}
                  fill='currentColor'
                  className='bi bi-wallet h-5 w-5'
                  viewBox='0 0 16 16'>
                  <path d='M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1' />
                </svg>
              </span>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
