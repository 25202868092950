/** @format */

import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import numbro from "numbro";
import { utils } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";
import Web3 from "web3";
import { PoolABI, PoolContractAddress } from "../Contracts/PoolsABI";
import useBalance from "../Contracts/useBalance";
import useAllowance from "../Contracts/useAllowance";
import { ErcABI, ErcContractAddress } from "../Contracts/Abi";

const Pool = () => {
  const [selected, setSelected] = useState(1);
  const [selected1, setSelected1] = useState(1);
  const [selected2, setSelected2] = useState(1);
  const [bnbAmount0, setBnbAmount0] = useState(null);
  const [bnbAmount1, setBnbAmount1] = useState(null);
  const [bnbAmount2, setBnbAmount2] = useState(null);
  const [reward, setReward] = useState(null);
  const [stakeBalance0, setStakeBalance0] = useState(null);
  const [stakeBalance1, setStakeBalance1] = useState(null);
  const [stakeBalance2, setStakeBalance2] = useState(null);
  const [btn, setBtn] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingUn, setloadingUn] = useState(false);

  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { open, close } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");

  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };

  const [yBNBbalance] = useBalance(
    "0x99B4D0b0a078AD86B3867C3822895E9705f7d56c",
    18,
  );

  function calcPerc0(amount) {
    setBnbAmount0((yBNBbalance / 100) * amount);
  }
  function calcPerc1(amount) {
    setBnbAmount1((yBNBbalance / 100) * amount);
  }
  function calcPerc2(amount) {
    setBnbAmount2((yBNBbalance / 100) * amount);
  }

  function calcPerc0un(amount) {
    setBnbAmount0((stakeBalance0 / 100) * amount);
  }
  function calcPerc1un(amount) {
    setBnbAmount1((stakeBalance1 / 100) * amount);
  }
  function calcPerc2un(amount) {
    setBnbAmount2((stakeBalance2 / 100) * amount);
  }
  const handleButton = buttonId => {
    setSelected(buttonId);
    setBnbAmount0("");
  };
  const handleButton1 = buttonId => {
    setSelected1(buttonId);
    setBnbAmount1("");
  };
  const handleButton2 = buttonId => {
    setSelected2(buttonId);
    setBnbAmount2("");
  };
  function purchaseSuccessful() {
    setBtn(false);
    toast.success("You have successfully deposit!");
  }

  function purchaseFailed() {
    setBtn(false);
    toast.error("Unable to handle your request, try again!");
  }

  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                             0
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  const handleDeposit0 = async () => {
    try {
      console.log(bnbAmount0, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (!bnbAmount0 || isNaN(bnbAmount0) || bnbAmount0 <= 0) {
        setBtn(false);
        return toast.error("Amount should be a valid number greater than 0");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);
      const invest = await contract.methods
        .deposit(web3.utils.toWei(String(bnbAmount0), "ether"), 0)
        .send({
          from: address,
          // value: web3.utils.toWei(String(bnbAmount0), "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });

      if (invest) {
        purchaseSuccessful();
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };

  const handleUnStake0 = async () => {
    if (address) {
      if (bnbAmount0 != "" && bnbAmount0 != null && bnbAmount0 != 0) {
        try {
          setloadingUn(true);
          const rpcGasPrice = await web3.eth.getGasPrice();

          const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

          const withdraw = contract.methods
            .withdraw(web3.utils.toWei(String(bnbAmount0), "ether"), 0)
            .send({
              from: address,
              // gas: 1000000,
              gasPrice: rpcGasPrice,
            })
            .then(res => {
              setloadingUn(false);
              toast.success("Successfully UnStake");
              setBnbAmount0("");
            })
            .catch(er => {
              setloadingUn(false);
              setBnbAmount0("");
              toast.error(
                "Something went wrong, you can ask in telegram group about this issue",
              );
            });
        } catch (error) {
          setBtn(false);
          // console.log(5, error);
        }
      } else {
        setloadingUn(false);
        toast.error("Amount should not be empty");
      }
    } else {
      setloadingUn(false);
      toast.info(
        "Unable to detect any wallet, please connect metamask or trust wallet",
      );
    }
  };

  const handleHarvest0 = async () => {
    setloading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const harvest = contract.methods
      .harvest()
      .send({
        from: address,
        value: web3.utils.toWei("0", "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      })
      .then(res => {
        setloading(false);
        toast.success("Successfully Harvest");
      })
      .catch(er => {
        setloading(false);
        toast.error(
          "Something went wrong, you can ask in telegram group about this issue",
        );
      });
  };
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                               1
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  const handleDeposit1 = async () => {
    try {
      console.log(bnbAmount1, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (!bnbAmount1 || isNaN(bnbAmount1) || bnbAmount1 <= 0) {
        setBtn(false);
        return toast.error("Amount should be a valid number greater than 0");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);
      const invest = await contract.methods
        .deposit(web3.utils.toWei(String(bnbAmount1), "ether"), 1)
        .send({
          from: address,
          // value: web3.utils.toWei(String(bnbAmount0), "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });

      if (invest) {
        purchaseSuccessful();
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };

  const handleUnStake1 = async () => {
    if (address) {
      if (bnbAmount1 != "" && bnbAmount1 != null && bnbAmount1 != 0) {
        try {
          setloadingUn(true);
          const rpcGasPrice = await web3.eth.getGasPrice();

          const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

          const withdraw = contract.methods
            .withdraw(web3.utils.toWei(String(bnbAmount1), "ether"), 1)
            .send({
              from: address,
              // gas: 1000000,
              gasPrice: rpcGasPrice,
            })
            .then(res => {
              setloadingUn(false);
              toast.success("Successfully withdraw");
              setBnbAmount1("");
            })
            .catch(er => {
              setloadingUn(false);
              setBnbAmount1("");
              toast.error(
                "Something went wrong, you can ask in telegram group about this issue",
              );
            });
        } catch (error) {
          setBtn(false);
          // console.log(5, error);
        }
      } else {
        setloadingUn(false);
        toast.error("Amount should not be empty");
      }
    } else {
      setloadingUn(false);
      toast.info(
        "Unable to detect any wallet, please connect metamask or trust wallet",
      );
    }
  };

  const handleHarvest1 = async () => {
    setloading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const harvest = contract.methods
      .harvest()
      .send({
        from: address,
        value: web3.utils.toWei("0", "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      })
      .then(res => {
        setloading(false);
        toast.success("Successfully Harvest");
      })
      .catch(er => {
        setloading(false);
        toast.error(
          "Something went wrong, you can ask in telegram group about this issue",
        );
      });
  };

  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                             2
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  const handleDeposit2 = async () => {
    try {
      console.log(bnbAmount2, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      if (!bnbAmount2 || isNaN(bnbAmount2) || bnbAmount2 <= 0) {
        setBtn(false);
        return toast.error("Amount should be a valid number greater than 0");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);
      const invest = await contract.methods
        .deposit(web3.utils.toWei(String(bnbAmount2), "ether"), 2)
        .send({
          from: address,
          // value: web3.utils.toWei(String(bnbAmount0), "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });

      if (invest) {
        purchaseSuccessful();
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };

  const handleUnStake2 = async () => {
    if (address) {
      if (bnbAmount2 != "" && bnbAmount2 != null && bnbAmount2 != 0) {
        try {
          setloadingUn(true);
          const rpcGasPrice = await web3.eth.getGasPrice();

          const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

          const withdraw = contract.methods
            .withdraw(web3.utils.toWei(String(bnbAmount2), "ether"), 2)
            .send({
              from: address,
              // gas: 1000000,
              gasPrice: rpcGasPrice,
            })
            .then(res => {
              setloadingUn(false);
              toast.success("Successfully withdraw");
              setBnbAmount2("");
            })
            .catch(er => {
              setloadingUn(false);
              setBnbAmount2("");
              toast.error(
                "Something went wrong, you can ask in telegram group about this issue",
              );
            });
        } catch (error) {
          setBtn(false);
          // console.log(5, error);
        }
      } else {
        setloadingUn(false);
        toast.error("Amount should not be empty");
      }
    } else {
      setloadingUn(false);
      toast.info(
        "Unable to detect any wallet, please connect metamask or trust wallet",
      );
    }
  };

  const handleHarvest2 = async () => {
    setloading(true);
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const harvest = contract.methods
      .harvest()
      .send({
        from: address,
        value: web3.utils.toWei("0", "ether"),
        // gas: 1000000,
        gasPrice: rpcGasPrice,
      })
      .then(res => {
        setloading(false);
        toast.success("Successfully Harvest");
      })
      .catch(er => {
        setloading(false);
        toast.error(
          "Something went wrong, you can ask in telegram group about this issue",
        );
      });
  };

  let allowanceWeb3 = useAllowance(ErcContractAddress, PoolContractAddress);
  const [allowance, setAllowance] = useState("0");
  const MAX_UINT =
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                             approve
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  useEffect(() => {
    setAllowance(allowanceWeb3);
  }, [allowanceWeb3]);

  const approve = async () => {
    try {
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();
      const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
      const approvalTransaction = await contract?.methods
        .approve(PoolContractAddress, MAX_UINT)
        .send({
          from: address,
          value: web3.utils.toWei("0", "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });
      if (approvalTransaction) {
        toast.success("Approval successful");
        console.log("Approval==>", approvalTransaction);
        setAllowance(MAX_UINT);
        setBtn(false);
      } else {
        toast.error("Approval transaction failed");
      }
    } catch (error) {
      console.error("Error during approval:", error);
      setBtn(false);
    }
  };

  const handleUserInfo0 = async () => {
    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const userInfo = contract.methods
      .userInfo(0, address)
      .call()
      .then(res => {
        // console.log(res);
        setStakeBalance0(getFullDisplayBalance(res?.amount));
      })
      .catch(er => {});
  };
  const handleUserInfo1 = async () => {
    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const userInfo = contract.methods
      .userInfo(1, address)
      .call()
      .then(res => {
        // console.log(res);
        setStakeBalance1(getFullDisplayBalance(res?.amount));
      })
      .catch(er => {});
  };
  const handleUserInfo2 = async () => {
    const contract = new web3.eth.Contract(PoolABI, PoolContractAddress);

    const userInfo = contract.methods
      .userInfo(2, address)
      .call()
      .then(res => {
        // console.log(res);
        setStakeBalance2(getFullDisplayBalance(res?.amount));
      })
      .catch(er => {});
  };
  useEffect(() => {
    if (chain?.id === 56) {
      handleUserInfo0();
      handleUserInfo1();
      handleUserInfo2();
      const interval = setInterval(() => {
        handleUserInfo0();
        handleUserInfo1();
        handleUserInfo2();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [address]);
  React.useEffect(() => {
    document.title = "Yield 24 - Trenches Pools";
  }, []);
  return (
    <div className=' md:px-10 flex-grow w-full md:max-w-[65%] lg:max-w-[83%] ms-auto   relative pt-5 md:pt-20'>
      <div className='container m-auto px-6 text-gray-500 md:px-10 xl:px-0'>
        <div className='mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-3'>
          <div className='card_main rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-3'>
            <div className=' space-y-4'>
              <h3 className='text-2xl h-16 flex items-center justify-center font-semibold text-center bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                Senior Trench
              </h3>
              <div className=' h-28 flex  items-center justify-around font-head'>
                <h3 className=' block  font-medium text-black text-2xl'>
                  APY{" "}
                </h3>
                <h3 className=' block font-bold text-2xl'>14% </h3>
              </div>
            </div>
            <img
              src='assets/pool1.svg'
              className='w-2/3 m-auto opacity-40'
              alt='illustration'
              loading='lazy'
              width={900}
              height={600}
            />
            {isConnected ? (
              chain?.id === 56 ? (
                allowance === "0" ? (
                  <div
                    onClick={approve}
                    className='flex mt-5 w-52 items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                    <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                      {btn ? (
                        <>
                          <div
                            class='spinner-border text-dark spinner-border-sm'
                            role='status'>
                            <span class='sr-only'>Loading...</span>
                          </div>
                          &nbsp; Processing transaction...
                        </>
                      ) : (
                        <span className='mx-5 space-x-3'>Approve</span>
                      )}
                    </span>
                  </div>
                ) : (
                  <div>
                    <div className='flex items-center flex-col-wrap m-auto mt-5'>
                      <div
                        className={`btn_buy ${
                          selected === 1
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton(1)}>
                        Deposit
                      </div>
                      <div
                        className={`btn_sell ${
                          selected === 2
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton(2)}>
                        Withdraw
                      </div>
                    </div>
                    {selected === 1 ? (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px] '>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount0 || ""}
                                onChange={e => setBnbAmount0(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc0(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {yBNBbalance
                                ? parseFloat(yBNBbalance).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleDeposit0();
                            }}
                            disabled={btn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {btn ? (
                                <>
                                  <div
                                    class='spinner-border text-dark spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Deposit</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px]'>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount0 || ""}
                                onChange={e => setBnbAmount0(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc0un(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {stakeBalance0
                                ? parseFloat(stakeBalance0).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>

                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleUnStake0();
                            }}
                            disabled={loadingUn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {loadingUn ? (
                                <>
                                  <div
                                    class='spinner-border text-dark spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Withdraw</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )
              ) : (
                <div className='text-center'>
                  {chains.map(x => (
                    <button
                      disabled={!switchNetwork || x.id === chain?.id}
                      key={x.id}
                      className='flex max-w-4xl btn font-para text-lg m-auto mt-5 border-0 fw-bold items-center  font-para py-3 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.(x.id)}>
                      Switch {x.name} &nbsp;
                      {isLoading && pendingChainId === x.id && (
                        <div
                          class='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              )
            ) : (
              <div
                onClick={open}
                className='flex mt-5 w-52 items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                  Connect Wallet
                </span>
              </div>
            )}
          </div>
          <div className='card_main rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-3'>
            <div className=' space-y-4'>
              <h3 className='text-2xl  h-16 flex items-center justify-center  text-center font-semibold bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                Mezzanine Trench
              </h3>
              <div className=' h-28 flex  items-center justify-around'>
                <h3 className='font-head block font-medium text-black text-2xl'>
                  APY{" "}
                </h3>
                <h3 className='font-head block font-bold text-2xl'>32% </h3>
              </div>
            </div>
            <img
              src='assets/pool3.svg'
              className='w-2/3 m-auto opacity-40'
              alt='illustration'
              loading='lazy'
              width={900}
              height={600}
            />
            {isConnected ? (
              chain?.id === 56 ? (
                allowance === "0" ? (
                  <div
                    onClick={approve}
                    className='flex mt-5 w-52 items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                    <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                      {btn ? (
                        <>
                          <div
                            class='spinner-border text-dark spinner-border-sm'
                            role='status'>
                            <span class='sr-only'>Loading...</span>
                          </div>
                          &nbsp; Processing transaction...
                        </>
                      ) : (
                        <span className='mx-5 space-x-3'>Approve</span>
                      )}
                    </span>
                  </div>
                ) : (
                  <div>
                    <div className='flex items-center m-auto mt-5'>
                      <div
                        className={`btn_buy ${
                          selected1 === 1
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton1(1)}>
                        Deposit
                      </div>
                      <div
                        className={`btn_sell ${
                          selected1 === 2
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton1(2)}>
                        Withdraw
                      </div>
                    </div>
                    {selected1 === 1 ? (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px] '>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount1 || ""}
                                onChange={e => setBnbAmount1(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc1(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {yBNBbalance
                                ? parseFloat(yBNBbalance).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleDeposit1();
                            }}
                            disabled={btn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {btn ? (
                                <>
                                  <div
                                    class='spinner-border text-dark spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Deposit</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px]'>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount1 || ""}
                                onChange={e => setBnbAmount1(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc1un(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {stakeBalance1
                                ? parseFloat(stakeBalance1).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleUnStake0();
                            }}
                            disabled={loadingUn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {loadingUn ? (
                                <>
                                  <div
                                    class='spinner-border text-dark spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Withdraw</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )
              ) : (
                <div className='text-center'>
                  {chains.map(x => (
                    <button
                      disabled={!switchNetwork || x.id === chain?.id}
                      key={x.id}
                      className='flex max-w-4xl btn font-para text-lg m-auto mt-5 border-0 fw-bold items-center  font-para py-3 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.(x.id)}>
                      Switch {x.name} &nbsp;
                      {isLoading && pendingChainId === x.id && (
                        <div
                          class='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              )
            ) : (
              <div
                onClick={open}
                className='flex mt-5 w-52 items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                  Connect Wallet
                </span>
              </div>
            )}
          </div>
          <div className='card_main rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-3'>
            <div className=' space-y-4'>
              <h3 className='text-2xl  h-16 flex items-center justify-center  text-center font-semibold bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                Junior Trench
              </h3>
              <div className=' h-28 flex  items-center justify-around '>
                <h3 className='font-head block font-medium text-black text-2xl'>
                  APY{" "}
                </h3>
                <h3 className='font-head block font-bold text-2xl'>56% </h3>
              </div>
            </div>
            <img
              src='assets/pool2.svg'
              className='w-2/3 m-auto opacity-40'
              alt='illustration'
              loading='lazy'
              width={900}
              height={600}
            />
            {isConnected ? (
              chain?.id === 56 ? (
                allowance === "0" ? (
                  <div
                    onClick={approve}
                    className='flex mt-5 w-52 items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                    <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                      {btn ? (
                        <>
                          <div
                            class='spinner-border text-dark spinner-border-sm'
                            role='status'>
                            <span class='sr-only'>Loading...</span>
                          </div>
                          &nbsp; Processing transaction...
                        </>
                      ) : (
                        <span className='mx-5 space-x-3'>Approve</span>
                      )}
                    </span>
                  </div>
                ) : (
                  <div>
                    <div className='flex items-center m-auto mt-5'>
                      <div
                        className={`btn_buy ${
                          selected2 === 1
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton2(1)}>
                        Deposit
                      </div>
                      <div
                        className={`btn_sell ${
                          selected2 === 2
                            ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                            : "not-selected bg-[#c1c1c7]"
                        }`}
                        onClick={() => handleButton2(2)}>
                        Withdraw
                      </div>
                    </div>
                    {selected2 === 1 ? (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px] '>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount2 || ""}
                                onChange={e => setBnbAmount2(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc2(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {yBNBbalance
                                ? parseFloat(yBNBbalance).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleDeposit2();
                            }}
                            disabled={btn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {btn ? (
                                <>
                                  <div
                                    class='spinner-border text-dark spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Deposit</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className='my-5'>
                          <div className='relative flex w-full max-w-[24rem] m-auto '>
                            <div className='relative h-12 w-full min-w-[200px]'>
                              <input
                                type='number'
                                className='peer h-full w-full rounded-[7px]  borde font-head border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                                placeholder=' '
                                value={bnbAmount2 || ""}
                                onChange={e => setBnbAmount2(e.target.value)}
                              />
                              <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Enter Amount
                              </label>
                            </div>
                            <button
                              onClick={() => calcPerc2un(100)}
                              className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                              type='button'>
                              Max
                            </button>
                          </div>
                          <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                            Balance:{" "}
                            <strong>
                              {" "}
                              {stakeBalance2
                                ? parseFloat(stakeBalance2).toFixed(3) + " yBNB"
                                : "0"}
                            </strong>
                          </h5>
                        </div>
                        <div className='text-center'>
                          <button
                            onClick={() => {
                              handleUnStake2();
                            }}
                            disabled={loadingUn}
                            className='flex btn max-w-4xl border-0  items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                              {loadingUn ? (
                                <>
                                  <div
                                    class='spinner-border text-dark spinner-border-sm'
                                    role='status'>
                                    <span class='sr-only'>Loading...</span>
                                  </div>
                                  &nbsp; Processing transaction...
                                </>
                              ) : (
                                <span className='mx-5 space-x-3'>Withdraw</span>
                              )}
                            </span>
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                )
              ) : (
                <div className='text-center'>
                  {chains.map(x => (
                    <button
                      disabled={!switchNetwork || x.id === chain?.id}
                      key={x.id}
                      className='flex max-w-4xl btn font-para text-lg m-auto mt-5 border-0 fw-bold items-center  font-para py-3 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.(x.id)}>
                      Switch {x.name} &nbsp;
                      {isLoading && pendingChainId === x.id && (
                        <div
                          class='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  ))}
                </div>
              )
            ) : (
              <div
                onClick={open}
                className='flex mt-5 w-52 items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                  Connect Wallet
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        // xmlns:svgjs='http://svgjs.dev/svgjs'
        className=' absolute bottom-0 w-full -z-50'
        width={1440}
        height={250}
        preserveAspectRatio='none'
        viewBox='0 0 1440 250'>
        <g mask='url("#SvgjsMask1006")' fill='none'>
          <rect
            width={1440}
            height={250}
            x={0}
            y={0}
            fill='rgba(255, 255, 255, 0)'
          />
          <path
            d='M26 250L276 0L364.5 0L114.5 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M278.6 250L528.6 0L782.1 0L532.1 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M504.20000000000005 250L754.2 0L884.7 0L634.7 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M715.8000000000001 250L965.8000000000001 0L1261.3000000000002 0L1011.3000000000001 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M1401 250L1151 0L1092.5 0L1342.5 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1178.4 250L928.4000000000001 0L700.4000000000001 0L950.4000000000001 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M936.8 250L686.8 0L418.29999999999995 0L668.3 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M710.1999999999999 250L460.19999999999993 0L143.69999999999993 0L393.69999999999993 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1304.192611426263 250L1440 114.19261142626311L1440 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M0 250L135.8073885737369 250L 0 114.19261142626311z'
            fill='url("#SvgjsLinearGradient1008")'
          />
        </g>
        <defs>
          <mask id='SvgjsMask1006'>
            <rect width={1440} height={250} fill='#ffffff' />
          </mask>
          <linearGradient
            x1='0%'
            y1='100%'
            x2='100%'
            y2='0%'
            id='SvgjsLinearGradient1007'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
          <linearGradient
            x1='100%'
            y1='100%'
            x2='0%'
            y2='0%'
            id='SvgjsLinearGradient1008'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Pool;
