/** @format */

import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  return (
    <div className='md:flex flex-col md:flex-row md:min-h-screen  md:fixed relative'>
      <div
        onClick={() => setOpen(!open)}
        className='flex flex-col w-full md:w-72 text-gray-700 rounded-b-3xl md:rounded-b  bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] transition-all dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0'>
        <div className='flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between'>
          <a
            href='https://y24.io/'
            target='_blank'
            rel='noreferrer'
            className='text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline'>
            <img src='assets/logo-hr.png' className='w-56 block' alt='' />
          </a>
          <button
            className='rounded-lg md:hidden focus:outline-none focus:shadow-outline'
            onClick={() => setOpen(!open)}>
            <svg fill='currentColor' viewBox='0 0 20 20' className='w-6 h-6'>
              {!open ? (
                <path
                  fillRule='evenodd'
                  d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z'
                  clipRule='evenodd'
                />
              ) : (
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              )}
            </svg>
          </button>
        </div>
        <nav
          className={`flex-grow md:block px-4 pb-4 mt-3 md:pb-0 md:overflow-y-auto  ${
            open ? "block" : "hidden"
          }`}>
          <a
            target='_blank'
            rel='noreferrer'
            className='block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900  rounded-lg dark-mode: transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
            href='https://y24.io/airdrop'>
            Airdrop
          </a>
          <Link
            className={` ${
              pathname === "/"
                ? "block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900 transition-transform transform  bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] rounded-lg dark-mode: dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                : "block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900 transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]   rounded-lg dark-mode: dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline "
            }`}
            to='/'>
            Leaderboard
          </Link>
          <Link
            className={` ${
              pathname === "/lrt-stake"
                ? "block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900 transition-transform transform  bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  rounded-lg dark-mode: dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                : "block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900 transition-transform transform  hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  rounded-lg dark-mode: dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline "
            }`}
            to='/lrt-stake'>
            LRT Staking
          </Link>
          <Link
            className={` ${
              pathname === "/pool"
                ? "block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900 transition-transform transform bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  rounded-lg dark-mode: dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                : "block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900 transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] rounded-lg dark-mode: dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline "
            }`}
            to='/pool'>
            Trench Pool
          </Link>
          <a
            target='_blank'
            rel='noreferrer'
            className='block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900  rounded-lg dark-mode: transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
            href='https://stake.y24.io/'>
            Y24 Staking
          </a>
          <Link
            className={` ${
              pathname === "/Governance"
                ? "block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900 transition-transform transform  bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  rounded-lg dark-mode: dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                : "block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900 transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] rounded-lg dark-mode: dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline "
            }`}
            to='/Governance'>
            Governance
          </Link>

          <a
            target='_blank'
            rel='noreferrer'
            className='block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900  rounded-lg dark-mode: transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
            href='https://y24-io.gitbook.io/y24/'>
            Documentation
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            className='flex  items-center gap-2 px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900  rounded-lg dark-mode: transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
            href='https://pancakeswap.finance/swap?outputCurrency=0x652000ba3c230d83279AEC84A49d41d706AFB0F1'>
            Buy Y24{" "}
            <img
              src='https://stake.y24.io/assets/cake.svg'
              className='w-6 h-6 img-fluid'
              alt=''
            />
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            className='block px-4 py-3 mt-1 text-sm font-bold font-para text-gray-900  rounded-lg dark-mode: transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
            href='https://blocksafu.com/audit/0x652000ba3c230d83279AEC84A49d41d706AFB0F1'>
            Audit
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            className='flex  px-4 py-2 mt-1 text-sm font-bold font-para text-gray-900  rounded-lg dark-mode: transition-transform transform   hover:bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline'
            href='https://discord.gg/rDrSaKC7'>
            Get Invitation Code &nbsp;{" "}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='currentColor'
              className='bi bi-box-arrow-up-right'
              viewBox='0 0 16 16'>
              <path
                fillRule='evenodd'
                d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5'
              />
              <path
                fillRule='evenodd'
                d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z'
              />
            </svg>
          </a>
        </nav>
        <div className='flex items-center gap-3 pb-4 justify-center'>
          <a
            href='https://twitter.com/Y24io'
            target='_blank'
            rel='noreferrer'
            className='text-[#57546c] hover:text-[#57546c]'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              className='bi bi-twitter-x'
              viewBox='0 0 16 16'>
              <path d='M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z' />
            </svg>
          </a>
          <a
            href='https://t.me/y24io_official'
            target='_blank'
            rel='noreferrer'
            className='text-[#57546c] hover:text-[#57546c]'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              className='bi bi-telegram'
              viewBox='0 0 16 16'>
              <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z' />
            </svg>
          </a>
          <a
            href='https://discord.gg/rDrSaKC7'
            target='_blank'
            rel='noreferrer'
            className='text-[#57546c] hover:text-[#57546c]  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              className='bi bi-discord'
              viewBox='0 0 16 16'>
              <path d='M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612' />
            </svg>
          </a>
          <a
            href='https://medium.com/@y24io'
            target='_blank'
            rel='noreferrer'
            className='text-[#57546c] hover:text-[#57546c]'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              className='bi bi-medium'
              viewBox='0 0 16 16'>
              <path d='M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8m4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236M16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795' />
            </svg>
          </a>
          <a
            href='https://www.youtube.com/@Yield24io'
            target='_blank'
            rel='noopener noreferrer'
            className='text-[#57546c] hover:text-[#57546c]  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              fill='currentColor'
              className='bi bi-youtube'
              viewBox='0 0 16 16'>
              <path d='M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408z' />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
