/** @format */

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

const Base = ({ children }) => {
  return (
    <React.Fragment>
      {/* <Header /> */}
      <Sidebar />
      {children}
      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default Base;
